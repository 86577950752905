import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { isPayPalSetOnTheWindow } from 'businessLogic/Payment/Paypal';
import PayPalAppConnectButton from 'components/Core/Payment/Buttons/AppConnectButton/PayPalAppConnectButton';
import PayButtonWithConsent from 'components/Core/Payment/Buttons/PayButton/PayButtonWithConsent';
import PaypalPayBtn from 'components/Core/Payment/Buttons/PayPalPayBtn/PaypalPayBtn';
import PaymentForm from 'components/Core/Payment/PaymentForm/PaymentForm';
import SpinnerCentered from 'components/Shared/Spinner/SpinnerCentered/SpinnerCentered';
import { IxpExperiment } from 'reporting/SegmentIO';
import { nanopaySelectors } from 'store/nanopay/selectors';
import { onPayButtonClicked } from 'store/payment/slice';
import { saleSelectors } from 'store/sale/selectors';
import { RootState } from 'store/store';
import { fetchWallets } from 'store/wallet/slice';
import { colors } from 'styles/cp';
import { Auth } from 'types/Auth';
import { Config } from 'types/Config';
import { FeatureFlags } from 'types/FeatureFlags';
import { Insight } from 'types/Insight';
import { Nanopay } from 'types/Nanopay';
import { Payment as PaymentTypes } from 'types/Payment';
import { Wallet } from 'types/Wallet';

type PaymentProps = {
  currency: string;
  auth: Pick<Auth, 'isUserSignedIn'>;
  featureFlags: FeatureFlags;
  payment: Pick<
    PaymentTypes,
    | 'isPayPalCommerceInvoice'
    | 'paymentMethodType'
    | 'paymentError'
    | 'isAmountValid'
    | 'amount'
    | 'paymentStatus'
    | 'payPalProcessor'
  >;
  wallet: Pick<
    Wallet,
    'userWallets' | 'isDetectingApplePayStatus' | 'isDetectingPayPalStatus' | 'fetchWalletStatus'
  >;
  dueDate: string;
  ixp: { [key: string]: IxpExperiment };
  shouldShowPayButton?: boolean;
  fetchWallets: Function;
  recurringInfo: Record<string, any>;
  isSubscription: boolean;
  insight: Pick<Insight, 'isPreview'>;
  config: Config;
  onPayButtonClicked: () => void;
  subscriptionInfo: Record<string, any>;
  nanopay: Pick<Nanopay, 'hasNanopayConnected' | 'payingWithAccount'>;
};

class Payment extends Component<PaymentProps, {}> {
  constructor(props: PaymentProps) {
    super(props);
  }
  componentDidMount() {
    const { auth, wallet, fetchWallets } = this.props;
    if (auth.isUserSignedIn && !wallet.userWallets) {
      fetchWallets();
    }
  }
  render() {
    const {
      dueDate,
      wallet: { isDetectingApplePayStatus, isDetectingPayPalStatus, fetchWalletStatus },
      payment: { isPayPalCommerceInvoice, paymentMethodType },
      auth,
      recurringInfo,
      isSubscription,
      shouldShowPayButton = true,
      insight: { isPreview } = {},
    } = this.props;
    const isFetchingWallet =
      fetchWalletStatus === 'fetching' || (!fetchWalletStatus && auth.isUserSignedIn);

    if (!isPreview && (isDetectingApplePayStatus || isDetectingPayPalStatus || isFetchingWallet)) {
      return <SpinnerCentered />;
    }
    const renderPaymentButton = () => {
      if (paymentMethodType === 'paypal_ppaam' || paymentMethodType === 'venmo') {
        if (!isPayPalSetOnTheWindow(paymentMethodType)) {
          return <></>;
        }
        // @ts-ignore
        return <PaypalPayBtn />;
      }
      if (isPayPalCommerceInvoice && paymentMethodType === 'pp') {
        if (!isPayPalSetOnTheWindow(paymentMethodType)) {
          return <></>;
        }
        return (
          <PayPalAppConnectButton
            // @ts-ignore
            config={this.props.config}
            wallet={this.props.wallet}
            auth={this.props.auth}
            payment={this.props.payment}
            featureFlags={this.props.featureFlags}
          />
        );
      }
      if (paymentMethodType === 'nanopay') {
        const { nanopay } = this.props;
        if (nanopay) {
          const { hasNanopayConnected, payingWithAccount } = nanopay;
          if (!hasNanopayConnected || !payingWithAccount || !payingWithAccount.id) {
            return null;
          }
        } else {
          return null;
        }
      }
      if (!isPayPalCommerceInvoice) {
        return (
          <PayButtonWithConsent
            currency={this.props.currency}
            config={this.props.config}
            wallet={this.props.wallet}
            auth={this.props.auth}
            payment={this.props.payment}
            featureFlags={this.props.featureFlags}
            onPayButtonClicked={this.props.onPayButtonClicked}
            recurringInfo={recurringInfo}
            dueDate={dueDate}
            isSubscription={isSubscription}
            subscriptionInfo={this.props.subscriptionInfo}
            ixp={this.props.ixp}
          />
        );
      }
      return null;
    };
    return (
      <Fragment>
        <PaymentForm />
        {shouldShowPayButton && renderPaymentButton()}
        <style jsx>{`
          .bugs {
            ul {
              list-style: none;
              padding: 10px 0;
              margin: 0;
              text-align: center;

              :global(li) {
                display: inline-block;

                &:not(first-child) {
                  margin-left: 5px;
                }
              }
            }
          }
          .or-payment {
            display: flex;
            color: ${colors.gray02};
            justify-content: space-around;
            align-items: center;
            margin: 12px 0;
          }
          .ApplePay {
            margin-bottom: 12px;
          }
        `}</style>
      </Fragment>
    );
  }
}

function mapStateToProps(store: RootState) {
  const { sale, payment, insight, config, nanopay, wallet, auth, ixp, featureFlags } = store;
  return {
    currency: saleSelectors.currencySelector(sale),
    dueDate: saleSelectors.dueDateSelector(sale),
    auth,
    payment,
    nanopay,
    wallet,
    featureFlags,
    ixp,
    insight,
    config,
    isCADAndPaymentMethodNanopay: nanopaySelectors.isCADAndPaymentMethodNanopaySelector(
      saleSelectors.currencySelector(sale),
      payment.paymentMethodType
    ),
    recurringInfo: saleSelectors.getRecurringInfo(sale),
    isSubscription: saleSelectors.isSubscription(sale),
    subscriptionInfo: saleSelectors.subscriptionInfo(sale),
  };
}

export default injectIntl(
  // @ts-ignore
  connect(mapStateToProps, {
    onPayButtonClicked,
    fetchWallets,
    // @ts-ignore
  })(Payment)
);
